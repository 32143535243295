import { EventsGrid, Utils, HeroBrand, FormAttendV3, FormSponsorV3, Hero, SectionCallout, SEO, SocialShare, ActionButton, Link, FormContactUsV3 } from "Components";
import Config from "Data";
import { graphql } from "gatsby";
import Layout from "Layouts/default";
import moment from "moment";
import React from "react";
import { Gap } from "Styles";
import styled from "styled-components";
import { theme } from "Theme";

const BrandedColor = styled.span`
  color: ${Utils.BrandColor(Config.brand.toLowerCase())};
`;

const infoMessage = styled.h3`
  color: inherit;
  max-width: ${theme.layout.base};
`;

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};
class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentModal: null,
      shareEvent: null,
      showEvents: false,
      isActive: false,
    };
  }

  componentDidMount() {
    this.setState({
      currentModal: null,
      shareEvent: null,
      showEvents: false,
    });
  }

  toggleModal = (key, param) => (e) => {
    e.preventDefault();
    if (this.state.currentModal) {
      this.handleModalCloseRequest();
      return;
    }

    this.setState({
      ...this.state,
      currentModal: key,
      shareEvent: param.shareEvent,
      showEvents: param.showEvents,
    });
  };

  handleModalCloseRequest = () => {
    this.setState({
      ...this.state,
      currentModal: null,
    });
  };

  handleOnAfterOpenModal = () => {};

  render() {
    const { data } = this.props;
    const hero = data.hero.edges[0].node;
    const heroBackground = data.heroBackground.edges[0].node;

    const triangle = data.triangle;
    // let allEventsData = [].concat(data.events2020.edges, data.events2021.edges, data.events2022.edges, data.events2023.edges);
    // let allBrands = [].concat(data.brands2020.distinct, data.brands2021.distinct, data.brands2022.distinct, data.brands2023.distinct);

    let allEventsData = [].concat(data.events2025.edges);
    let allBrands = [].concat(data.brands2025.distinct);

    allBrands = allBrands.filter(onlyUnique);
    // console.log(allBrands);
    let allEvents = allEventsData.sort((a, b) => (a.node.date > b.node.date ? 1 : -1));
    const allRegions = ["apac", "canada", "emea", "latam", "usa"];

    const { currentModal } = this.state;
    let futureEvents = allEvents.filter((event) => {
      return !moment(event.node.date).isBefore(moment(), "day");
    });
    futureEvents.sort((a, b) => (a.node.date > b.node.date ? 1 : -1));
    // console.log({ futureEvents })

    return (
      <Layout locale={"en-us"} region={"us"} sticky={true} transparentHeader={false} transparentFooter={false} path={this.props.path}>
        <SEO
          pathname="/events/"
          title={`2025 Events | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={`Interested in attending or sponsoring one of our upcoming events?` || ""}
          keywords={"pageKeywords"}
          // banner={heroBackground.childrenFile[0].childImageSharp.gatsbyImageData.images.sources[0].srcSet || ""}
          events={allEvents}
        />
        <SocialShare
          pathname="/events/"
          title={`2025 Events | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={`Interested in attending or sponsoring one of our upcoming events?` || ""}
          // banner={heroBackground.childrenFile[0].childImageSharp.gatsbyImageData.images.sources[0].srcSet || ""}
        />
        <Hero
          height={50}
          justify={"end"}
          featured={false}
          title={`2025 Events`}
          subtitle={`Interested in attending or sponsoring one of our upcoming events?`}
          image={heroBackground.childImageSharp.gatsbyImageData}
          color={"default"}
          animated={"true"}
          cta_action1_text={"Attend"}
          cta_action1={this.toggleModal("modal_attend", {
            shareEvent: null,
            showEvents: true,
          })}
          cta_action2_text={"Sponsor"}
          cta_action2={this.toggleModal("modal_sponsor", {
            shareEvent: null,
            showEvents: true,
          })}
          vimeoId={hero.video && hero.video !== null ? hero.video : undefined}
          track={"Hero"}
        />
        {/* <SectionCallout size={"tiny"} dark={true} grid={true} page={this.props}>
          <infoMessage>
            <h4 style={{ margin: 0, padding: "1rem 1rem", backgroundColor: "rgb(58, 58, 58)", borderRadius: 12 }}>
              Our 2024 dates will be announced shortly. Inquire{" "}
              <Link
                className={`action-cta`}
                onClick={this.toggleModal("modal_contactus", {
                  shareEvent: null,
                  showEvents: false,
                })}
                title={`Contact Us - 2024 Events`}
                track={"2024 Events"}
                style={{ color: "#6199ff", textDecoration: "underline" }}
              >
                HERE
              </Link>{" "}
              to attend or sponsor
            </h4>
          </infoMessage>
        </SectionCallout> */}
        <SectionCallout small={true} dark={false} grid={true} page={this.props} background={triangle.edges[0].node.childImageSharp.gatsbyImageData.images.sources[0].srcSet}>
          <EventsGrid
            button={true}
            countdown={false}
            events={allEvents}
            regions={allRegions}
            brands={allBrands}
            featured={false}
            big={false}
            filter={true}
            header={true}
            now={false}
            title={``}
            track={"Events Grid"}
            teaser={false}
            item_action ={this.toggleModal("modal_contactus", {
              shareEvent: true,
              showEvents: true,
            })}
          />
          {/* <SectionSubscribe
            dark={false}
            title={`Want to Stay informed?`}
            // subtitle={`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non, voluptates.`}
            postSubscribe={true}
            track={"Subscribe CTA"}
          /> */}
          <Gap />
        </SectionCallout>
        <FormAttendV3
          events={futureEvents}
          showEvents={this.state.showEvents}
          isOpen={currentModal === "modal_attend"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_attend", {
            shareEvent: null,
            showEvents: false,
          })}
        />
        <FormSponsorV3
          events={futureEvents}
          showEvents={this.state.showEvents}
          options={false}
          optionsLink={false}
          isOpen={currentModal === "modal_sponsor"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_sponsor", {
            shareEvent: null,
            showEvents: false,
          })}
        />
        <FormContactUsV3
          title={`Have a question for the ${Config.brand} team?`}
          subtitle="Call us or submit below:"
          isOpen={currentModal === "modal_contactus"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_contactus")}
        />
      </Layout>
    );
  }
}

export default Events;

export const query = graphql`
  query EventsUSQuery {
    hero: allGoogleSheetsEvents(filter: { block: { eq: "Hero" } }) {
      edges {
        node {
          id
          block
          heroImage
          title
          subtitle
          childrenFile {
            childImageSharp {
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
    }
    heroBackground: allFile(filter: { name: { eq: "CMO3" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
    triangle: allFile(filter: { name: { eq: "triangle" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }

    # events2020: allGoogleSheetOverview2020(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   totalCount
    #   edges {
    #     node {
    #       id
    #       brand
    #       region
    #       events
    #       eventTitle
    #       eventType
    #       eventTime
    #       audience
    #       industry
    #       panel
    #       stateCity
    #       date
    #       eventSite
    #       twitter
    #       image
    #       webinarLink
    #       country
    #       countryIso
    #     }
    #   }
    # }
    # regions2020: allGoogleSheetOverview2020(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   distinct(field: region)
    #   edges {
    #     node {
    #       region
    #     }
    #   }
    # }
    # brands2020: allGoogleSheetOverview2020(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   distinct(field: brand)
    #   edges {
    #     node {
    #       brand
    #     }
    #   }
    # }
    # events2021: allGoogleSheetOverview2021(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   totalCount
    #   edges {
    #     node {
    #       id
    #       brand
    #       region
    #       events
    #       eventTitle
    #       eventType
    #       eventTime
    #       audience
    #       industry
    #       panel
    #       stateCity
    #       date
    #       eventSite
    #       twitter
    #       image
    #       webinarLink
    #       country
    #       countryIso
    #     }
    #   }
    # }
    # regions2021: allGoogleSheetOverview2021(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   distinct(field: region)
    #   edges {
    #     node {
    #       region
    #     }
    #   }
    # }
    # brands2021: allGoogleSheetOverview2021(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   distinct(field: brand)
    #   edges {
    #     node {
    #       brand
    #     }
    #   }
    # }
    # events2022: allGoogleSheetOverview2022(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   totalCount
    #   edges {
    #     node {
    #       id
    #       brand
    #       region
    #       events
    #       eventTitle
    #       eventType
    #       eventTime
    #       audience
    #       industry
    #       panel
    #       stateCity
    #       date
    #       eventSite
    #       twitter
    #       image
    #       webinarLink
    #       country
    #       countryIso
    #     }
    #   }
    # }
    # regions2022: allGoogleSheetOverview2022(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   distinct(field: region)
    #   edges {
    #     node {
    #       region
    #     }
    #   }
    # }
    # brands2022: allGoogleSheetOverview2022(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   distinct(field: brand)
    #   edges {
    #     node {
    #       brand
    #     }
    #   }
    # }
    # events2023: allGoogleSheetOverview2023(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   totalCount
    #   edges {
    #     node {
    #       id
    #       brand
    #       region
    #       events
    #       eventTitle
    #       eventType
    #       eventTime
    #       audience
    #       industry
    #       panel
    #       stateCity
    #       date
    #       eventSite
    #       twitter
    #       image
    #       webinarLink
    #       country
    #       countryIso
    #     }
    #   }
    # }
    # regions2023: allGoogleSheetOverview2023(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   distinct(field: region)
    #   edges {
    #     node {
    #       region
    #     }
    #   }
    # }
    # brands2023: allGoogleSheetOverview2023(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
    #   distinct(field: brand)
    #   edges {
    #     node {
    #       brand
    #     }
    #   }
    # }
    events2025: allGoogleSheetOverview2025(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
      totalCount
      edges {
        node {
          id
          brand
          region
          events
          eventType
          eventTitle
          eventTime
          audience
          industry
          stateCity
          date
          eventSite
          twitter
          image  
          webinarLink
          country
        }
      }
    }
    regions2025: allGoogleSheetOverview2025(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
      distinct(field: region)
      edges {
        node {
          region
        }
      }
    }
    brands2025: allGoogleSheetOverview2025(filter: { calendar: { eq: true } }, sort: { order: DESC, fields: date }) {
      distinct(field: brand)
      edges {
        node {
          brand
        }
      }
    }
    # months: allGoogleSheetOverview(
    #   filter: {
    #     calendar: { eq: "TRUE" }
    #     brand: { ne: null }
    #     eventType: { in: ["conference", "gala", "camp", "webinar"] }
    #   }
    # ) {
    #   distinct(field: date)
    #   edges {
    #     node {
    #       region
    #       date
    #     }
    #   }
    # }
    # distinct_country: allListEvents(filter: { event: { active: { eq: 1 } } }) {
    #   distinct(field: event___country)
    # }
    # distinct_state: allListEvents(filter: { event: { active: { eq: 1 } } }) {
    #   distinct(field: event___state)
    # }
    # distinct_cities: allListEvents(filter: { event: { active: { eq: 1 } } }) {
    #   distinct(field: event___city)
    # }
    # distinct_sponsors: allListEvents {
    #   distinct(field: event___event_detail___companys___company_name)
    # }
    # distinct_speakers_companies: allListEvents {
    #   distinct(field: event___event_detail___speakers___company_name)
    # }
  }
`;
